
import axios from 'axios';
export default {
    async createLabSampleTestNormalRanges(params)  {
        return await axios.post(`lab_sample_test_normal_ranges/create` , params)
    },
    async createLabSampleTestNormalRangesList(params)  {
        return await axios.post(`lab_sample_test_normal_ranges/create/list` , params)
    },
    async updateLabSampleTestNormalRangesColumn(column , value , data)  {
        return await axios.put(`lab_sample_test_normal_ranges/update_list?${column}=${value}` , data)
    },
    async deleteLabSampleTestNormalRangesList(list)  {
        return await axios.delete(`lab_sample_test_normal_ranges/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportLabSampleTestNormalRanges(column , value)  {
        return await axios.get(`lab_sample_test_normal_ranges/report?${column}=${value}`)
    },
    async getAllLabSampleTestNormalRanges()  {
        return await axios.get(`lab_sample_test_normal_ranges/all`)
    },
    async getOneLabSampleTestNormalRanges(lab_sample_tests_normal_range_id)  {
        return await axios.get(`lab_sample_test_normal_ranges/all/${lab_sample_tests_normal_range_id}`)
    },
    async getLabSampleTestNormalRangesByColumn(column , value)  {
        return await axios.get(`lab_sample_test_normal_ranges/filter?column=${column}&value=${value}`)
    },
    async deleteLabSampleTestNormalRanges(lab_sample_tests_normal_range_id)  {
        return await axios.delete(`lab_sample_test_normal_ranges/delete/${lab_sample_tests_normal_range_id}`)
    },
    async updateLabSampleTestNormalRanges(lab_sample_tests_normal_range_id , params)  {
        return await axios.put(`lab_sample_test_normal_ranges/update/${lab_sample_tests_normal_range_id}` , params)
    },
    async get_list_lab_test_normal_ranges_by_column(column, values) {
        return await axios.get(`lab_sample_test_normal_ranges/get_list?column=${column}&values=${values}`)
        .then(r =>{
            return r
        })
        .catch(e =>{
            console.log(e);
            return e
        })
    },
}
        